import { Center, Box, useColorModeValue, Flex, Progress, Image, HStack, Heading, Text, Skeleton } from '@chakra-ui/react'
import React from 'react'
import { useState, useEffect } from 'react'
import { BsArrowUpRight } from "react-icons/bs"
import { RxCross1 } from "react-icons/rx"

function uploadImage(file, onProgress) {
    const url = `https://wr2lna9jlj.execute-api.us-east-2.amazonaws.com/Dev/polarbearapp/${file.name}`

    return new Promise((res, rej) => {
        const xhr = new XMLHttpRequest()
        xhr.open('PUT', url)
        xhr.setRequestHeader("Content-Type", `image/${file.name.split(".")[1]}`)

        xhr.onload = () => {
            const resp = JSON.parse(xhr.responseText)
            res(resp.url)
        }

        xhr.onerror = (evt) => rej(evt)
        xhr.upload.onprogress = (event) => {
            if(event.lengthComputable) {
                const percentage = (event.loaded/event.total) * 100
                onProgress(Math.round(percentage))
            }
        }        
        xhr.send(file)
    })
}

function predictImage(fileURL) {
    const url = `https://hpmr55i3xj.us-east-2.awsapprunner.com/prediction`

    var data = JSON.stringify({
        "imageURL": fileURL
    })
  
    return new Promise((res, rej) => {
        const xhr = new XMLHttpRequest()
        xhr.open('POST', url)
        xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
        xhr.onload = () => {
            const resp = JSON.parse(xhr.responseText)
            res(resp)
        }
        xhr.onerror = (evt) => rej(evt)
        console.log(data)
        xhr.send(data)
    })
}


function validate(file) {
    let acceptedFormats = ["jpeg", "png", "jpg"]
    let fileFormat = file.name.split(".")[1]
    
    return acceptedFormats.includes(fileFormat)
    
}

function ImageCard({file, onDelete, onUpload}) {
    const [progress, setProgress] = useState(0) 
    const [url, setUrl] = useState('')
    const [prediction, setPrediction] = useState('Loading')
    const [error, setError] = useState(false)
       
    useEffect(() => {
        async function upload() {
            const responseUrl = await uploadImage(file, setProgress)
            setUrl(responseUrl)
            const result = await predictImage(responseUrl)
            setPrediction(result['prediction'])
            onUpload(file, responseUrl)
        }
        if(validate(file)) {
            upload()
        } else {
            setError(true)
            setProgress(100)
        }
        
    }, [])
    
    return (
        <Center py={6}>
            <Box w="xs" rounded={'sm'} my={5} mx={[0, 5]} overflow={'hidden'} border={'1px'} boxShadow={useColorModeValue('6px 6px 0 black', '6px 6px 0 white')}>
                <Box h={'200px'} borderBottom={'1px'} borderColor="black">
                    <Image src={url} alt={file.name} roundedTop={'sm'} objectFit="cover" h="full" w="full" />
                    <Progress colorScheme='blue' size='sm' value={progress} hidden={progress === 100 ? true : false} />         
                </Box>
                <Box p={4}>
                    <Text color={error ? 'red' : 'gray'}  noOfLines={1}>
                        {file.name}
                    </Text>
                    { error ? 
                        <Text color={'red.500'} noOfLines={2}>
                            File does not support.
                        </Text>
                        :
                        <Skeleton isLoaded={prediction !== 'Loading'}>
                            <Heading color={'gray.500'} fontSize={'2xl'} noOfLines={2}>
                               {prediction}
                            </Heading>
                        </Skeleton>
                    }
                </Box>
                <HStack borderTop={'1px'}>
                    <Flex p={4} alignItems="center" justifyContent={'space-between'} roundedBottom={'sm'} cursor={'pointer'} w="full">
                        <Text fontSize={'md'} fontWeight={'semibold'}>
                            View more
                        </Text>
                        <BsArrowUpRight />
                    </Flex>
                    <Flex p={4} alignItems="center" justifyContent={'space-between'} roundedBottom={'sm'} borderLeft={'1px'} cursor="pointer" onClick={() => {onDelete(file)}}>
                        <RxCross1 fontSize={'24px'} />
                    </Flex>
                </HStack>
            </Box>
        </Center>
    )
}

export default ImageCard