import React from 'react'
import { Button, Modal, ModalHeader, Heading, ModalBody, ModalContent, ModalFooter, ModalCloseButton, ModalOverlay,
        useDisclosure } from '@chakra-ui/react'
import { MdOutlineContactSupport } from "react-icons/md"

function ContactUs() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <>
            <Button onClick={onOpen} size="md">
                <MdOutlineContactSupport />
            </Button>
            {/* <Button onClick={onOpen}>
                Open GeeksforGeeks Courses Modal
            </Button> */}
            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent borderRadius="md">
                    <ModalHeader>
                        <Heading as="h1">
                            Contact Us
                        </Heading>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <p>
                            In Progress.
                        </p>
                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ContactUs