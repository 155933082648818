import React from 'react'
import { Container, Box, Text, Flex, Spacer, Heading, Menu, MenuItem, MenuDivider, MenuButton,
        IconButton, MenuList, HStack, Button, useDisclosure, useColorModeValue, useColorMode, Image} from '@chakra-ui/react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { MdOutlineContactSupport } from "react-icons/md"
import { IoMoon } from "react-icons/io5"
import { LuSun } from "react-icons/lu"
import { RiUser5Line } from "react-icons/ri"
import { IoIosInformationCircleOutline } from "react-icons/io";
import AboutUs from './AboutUs'
import ContactUs from './ContactUs'

function NavBar() {
    const { onOpen } = useDisclosure()
    const {colorMode, toggleColorMode} = useColorMode()
    return (
        <Container maxW="7xl" p={{ base: 5, md: 10 }}>
            <Flex mb="30px" align="center">
                <HStack>
                    <Box>
                        <Image src="./images/logo.png" alt="PolarBearLogo" width={{ base: '50px', sm: '80px', md:'90px' }} height={{ base: '50px', sm: '80px', md:'100px'}} />
                    </Box>
                    <Text fontSize={{base: "2xl", md: "40"}} as={"span"} fontWeight={"bold"} bgGradient={"linear(to-r, cyan.400, blue.500)"} bgClip={"text"}>
                        PolarBearID
                    </Text>
                </HStack>
                <Spacer />
                <Box>
                    <HStack>
                        <Box display={['none', 'none', 'none']} > {/*FIX ME: Change to display={['none', 'none', 'block']} */}
                            <AboutUs/>
                        </Box>
                        <Box display={['none', 'none', 'none']} > {/*FIX ME: Change to display={['none', 'none', 'block']} */}
                            <ContactUs />
                        </Box>
                        <Box display={['none', 'none', 'none']} > {/*FIX ME: Change to display={['none', 'none', 'block']} */}
                            <Button onClick={()=> console.log("Login")}  size="md">
                                <RiUser5Line />
                            </Button>
                        </Box>

                        <Box display={['none', 'none', 'none']}> {/*FIX ME: Change to display={['block', 'block', 'none']} */}
                            <Menu>
                                <MenuButton as={IconButton} aria-label="Options" icon={<GiHamburgerMenu />} transition="all 0.2s" size="md"
                                color="white" variant="outline" bg={useColorModeValue('gray.400', 'gray.800')} _hover={{ bg: 'auto' }}
                                _focus={{ boxShadow: 'outline' }}/>
                                <MenuList fontSize="sm" zIndex={5}>
                                    <MenuItem icon={<IoIosInformationCircleOutline />} onClick={onOpen}>
                                        {' '}
                                        <Text textShadow="1px 1px #9c1786">Information</Text>
                                    </MenuItem>
                                    <MenuDivider />
                                    <MenuItem icon={<MdOutlineContactSupport />}>
                                        {' '}
                                        <Text textShadow="1px 1px #9c1786">Admin</Text>
                                    </MenuItem>
                                    <MenuItem icon={<RiUser5Line />}>
                                        {' '}
                                        <Text textShadow="1px 1px #9c1786">Admin</Text>
                                    </MenuItem>
                                </MenuList>
                            </Menu>
                        </Box>
                        <Button onClick={toggleColorMode}>
                                {colorMode === "light" ? <IoMoon /> : <LuSun size={20} />}
                        </Button>
                </HStack>
                </Box>
            </Flex>
        </Container>
    )
}

export default NavBar