import React from 'react'
import ImageCard from './ImageCard'
import { Center, Grid } from '@chakra-ui/react'

function ImageGrid({files, setFiles}) {

    function onUpload(file, url) {
        setFiles(curr => curr.map((item) => {
          if(item === file) {
            item.url = url
          }
          return item
        }))
    }
    function onDelete(file) {
        setFiles(curr => curr.filter((item) =>  item !== file ))
    }

    return (
        <Center>
            <Grid templateColumns={{base: "1fr", md:"repeat(3, 1fr)", lg:"repeat(4, 1fr)"}} gap={0}>
                {files.map((file, index) => (
                    <ImageCard key={index} file={file} onDelete={onDelete} onUpload={onUpload} />
                ))}
            </Grid>
        </Center>
    )
}

export default ImageGrid