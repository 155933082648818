import { Container, Stack, Text } from '@chakra-ui/react'
import './App.css'
import NavBar from './components/NavBar'
import UploadImages from './components/UploadImages'
import { useState } from 'react'
import ImageGrid from './components/ImageGrid'

function App() {

  const [files, setFiles] = useState([])
  return (
    <Stack minH={"100vh"}>
      <NavBar/>
      <Container maxW={"1200px"} my={-10} >
        <Text fontWeight={"bold"} letterSpacing={"2px"} textTransform={"2px"} textAlign={"center"} >
              <Text fontSize={{base: "3xl", md: "30"}} as={"span"} bgGradient={"linear(to-r, cyan.400, blue.500)"} bgClip={"text"}>
                An Image Identification System For Polar Bear
              </Text>
        </Text>
        <UploadImages py={2} setFiles={setFiles}/>
        <Stack py={5} spacing={5}>
             <ImageGrid py={10} files={files} setFiles={setFiles} />
        </Stack>
       
      </Container>
    </Stack>
    
  );
}

export default App;
