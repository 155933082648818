import { AspectRatio, Box, Container,Heading, Input, Stack, Text, Image} from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import {useDropzone} from 'react-dropzone'
import React, { useCallback } from 'react'
  
const first = {
    rest: {
      rotate: "-15deg",
      scale: 0.95,
      x: "-50%",
      filter: "grayscale(80%)",
      transition: {
        duration: 0.5,
        type: "tween",
        ease: "easeIn"
      }
    },
    hover: {
      x: "-70%",
      scale: 1.1,
      rotate: "-20deg",
      filter: "grayscale(0%)",
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    }
};
  
const second = {
    rest: {
      rotate: "15deg",
      scale: 0.95,
      x: "50%",
      filter: "grayscale(80%)",
      transition: {
        duration: 0.5,
        type: "tween",
        ease: "easeIn"
      }
    },
    hover: {
      x: "70%",
      scale: 1.1,
      rotate: "20deg",
      filter: "grayscale(0%)",
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    }
};
  
const third = {
    rest: {
      scale: 1.1,
      filter: "grayscale(80%)",
      transition: {
        duration: 0.5,
        type: "tween",
        ease: "easeIn"
      }
    },
    hover: {
      scale: 1.3,
      filter: "grayscale(0%)",
      transition: {
        duration: 0.4,
        type: "tween",
        ease: "easeOut"
      }
    }
};
  
const PreviewImage = ((props, ref) => {
    return (
      <Box bg="white" top="0" height="100%" width="100%" position="absolute" borderWidth="1px"
        borderStyle="solid" rounded="sm" borderColor="gray.400" as={motion.div} backgroundSize="cover" backgroundRepeat="no-repeat"
        backgroundPosition="center"  {...props} ref={ref}
      >
        <Image src={"./images/placeholder.jpeg"} objectFit="cover" h="full" w="full" />
      </Box>
    );
  });

function UploadImages({setFiles})  {
    const controls = useAnimation();
    const startAnimation = () => controls.start("hover");
    const stopAnimation = () => controls.stop();

    const onDrop = useCallback(acceptedFiles => {
      setFiles((curr) => [...curr, ...acceptedFiles])
    }, [])

    const {getRootProps, getInputProps} = useDropzone({onDrop})

    return (
        <Container >
          <AspectRatio width={{base: "180"}} ratio={{base:1, sm:1, md:1.8}}>
              <Box borderColor="gray.300" borderStyle="dashed" borderWidth="2px" rounded="md" shadow="sm" role="group" transition="all 150ms ease-in-out"
              _hover={{shadow: "md"}} as={motion.div} initial="rest" animate="rest" whileHover="hover">
                  <Box position="relative" height="100%" width="100%" {...getRootProps()}>
                      <Box position="absolute" top="0" left="0" height="100%" width="100%" display="flex" flexDirection="column" >
                          <Stack height="100%" width="100%" display="flex" alignItems="center" justify="center" spacing="4">
                              <Box my={0}  height="40" width="40" position="relative" borderRadius={3}>
                                  <PreviewImage variants={first} backgroundImage="./images/placeholder.jpeg"/>
                                  <PreviewImage variants={second} backgroundImage="./images/placeholder.jpeg"/>
                                  <PreviewImage variants={third} backgroundImage="./images/placeholder.jpeg"/>
                              </Box>
                              <Stack p="2" textAlign="center" spacing="1">
                                  <Heading fontSize="lg" fontWeight="bold">
                                      Drop images here
                                  </Heading>
                                  <Text fontWeight="light">or click to upload</Text>
                              </Stack>
                          </Stack>
                      </Box>
                      <Input type="file" height="100%" width="100%" position="absolute" top="0" left="0" opacity="0" aria-hidden="true"
                      accept="image/*" onDragEnter={startAnimation} onDragLeave={stopAnimation} {...getInputProps()} />
                  </Box>
              </Box>
          </AspectRatio>
        </Container>
    );
  }
  
  export default UploadImages